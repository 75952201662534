#contactPage {
    min-height: calc(100vh - 9rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

textarea {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.contactTitle {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
    max-width: 42rem;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .message {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 1rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40,40,40);
}

.name::placeholder, .email::placeholder, .message::placeholder {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.5;
    letter-spacing: normal;
}

.name:focus, .email:focus, .message:focus {
    outline: transparent;
    border:1px solid rgb(61,163,255);
}

.textareaContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 40rem;
    padding: 0 0;
}

.textareaWrapper {
    position: relative;
    padding: 0 0;
}

.message {
    resize: none;
    overflow: hidden;
    padding-bottom: 2.4rem;
    margin-left: 0rem;
    margin-right: 0rem;
}

.charCount {
    position: absolute;
    bottom: 1.4rem;
    right: 2rem;
    z-index: 10;
    font-size: 1rem;
}

.successMsg {
    font-size: 1.1rem;
    margin: 0.8rem;
    text-align: center;
}

.submitBtn {
    font-size: medium;
    background: white;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    margin-top: 0.5rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
}

.submitBtn:hover {
    background-color: rgb(61,163,255);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Shadow for 3D effect */
}

.submitBtn:disabled {
    background-color: #999999;
    color: #666666;
    cursor: not-allowed;
    opacity: 0.7;
}

.submitBtn:disabled:hover {
    background-color: #999999;
    box-shadow: none;
}

.links {
    display: flex;
    flex-wrap: wrap;
}

.link {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
    transition: transform 0.3s ease;
}

.link:hover {
    transform: scale(1.15);
  }