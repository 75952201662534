.navbar {
    height: 5rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 3;
    background: rgb(30, 30, 30);
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
    color: white;
}

.desktopMenuListItem:hover {
    color: rgb(61,163,255);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(61,163,255);
}

.active {
    color: rgb(61,163,255);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(61,163,255);
}

.burgerMenu {
    position: absolute;
    right: 3rem;
    top: 1.5rem;
    display: none;
    object-fit: cover;
    height: 2rem;
}

.mobileMenu {
    position: absolute;
    top: 4rem;
    right: 2.6rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 12rem;
    background: rgb(40,40,40);
    border-radius: 1rem;
}

.mobileMenuListItem {
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30,30,30);
    border-radius: 0.4rem;
}

@media screen and (max-width: 500px) {
    .burgerMenu {
        display: flex;
    }
    .desktopMenu {
        display: none;
    }
}