.resume-container {
    max-width: 60vw; /* Increase container width */
    margin: 1rem auto;
}

.header {
    margin-bottom: 2rem; /* Add bottom margin */
    margin: 2rem 1.5rem;
    margin-right: 0rem;
    display: flex;
    justify-content: space-between; /* Distribute space between the two divs */
    align-items: center; /* Vertically center the content */
}

.download-button {
    background-color: white;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 1rem;
    font-size: 1rem;
    cursor: pointer;
    max-width: 20rem;
    min-height: 3rem;
    color: black;
    text-align: center;
}

.download-button:hover {
    background-color: rgb(61,163,255);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Shadow for 3D effect */
    color: black;
}

section {
    margin-bottom: 2.5rem; /* Increase spacing between sections */
}

section h3 {
    font-size: 1.6rem;
    font-weight: bold;
}

section h4 {
    font-size: 1.1rem;
    font-weight: bold;
}

.company-name {
    font-weight: normal;
}

.resume-bullet-points {
    margin-top: 0.5rem;
    padding-left: 1rem; /* Adjust the value as needed */
}

.subtitle {
    display: flex;
    justify-content: space-between; /* Distribute space between the two divs */
    align-items: center; /* Vertically center the content */
}

.experience, .projects, .education, .skills {
    background-color: #333; /* Slightly lighter background */
    padding: 2rem 3rem;
    border-radius: 1rem;
}

.experience-item, .projects-item, .education-item {
    margin-top: 1.25rem;
}