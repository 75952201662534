#projects {
    margin: 0 auto;
    min-height: calc(100vh - 9rem);
    width: 100vw;
    max-width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.projectsTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.projectsSubtitle {
    margin-top: -0.8rem;
    margin-bottom: 2rem;
}

.projectsDesc {
    font-weight: 300;
    font-size: 1rem;
}

.projectPagesDisclaimer {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    margin-bottom: 1rem;
    color: crimson;
}

.projectBulletPoints {
    margin-left: 2rem;
    align-self: start;
}

.projectImages {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 100rem;
}

.projectContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.overlay {
    position: absolute;
    bottom: 1rem;
    left: 0.8rem;
    right: 0.8rem;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: 94.7%;
    height: 90%;
    opacity: 0;
    transition: opacity 0.5s ease;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlayText {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.projectContainer:hover .overlay {
    opacity: 1;
}

.projectsImg {
    object-fit: cover;
    height: 16rem;
    margin: 0.8rem;
    border-radius: 0.4rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}

.projectsImg:hover {
    transform: scale(1.05); /* Slightly scale up the image */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}