.projectPages {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;
}

.projectPagesDesc {
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    font-weight: 300;
    margin-bottom: 1rem;
    align-self: flex-start;
}

.projectPagesImg {
    max-width: 75%;
    max-height: 75%;
    border-radius: 0.25rem;
    align-items: center;
}

.projectPagesImgCaption {
    margin: 1.5rem;
}