#Home {
    height: calc(100vh - 9rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative
}

/* .bg {
    position: absolute;
    top: -4rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
} */

.bg {
    position: absolute;
    top: -4rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
}

.homeContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hello {
    font-size: 1.75rem;
    font-weight: 100;
}

.introName {
    color: rgb(61,163,255);
}

.introParagraph {
    font-size: medium;
    font-weight: 300;
    max-width: 36rem;
    margin-bottom: 1rem;;
}

.btn {
    background-color: white;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    max-width: 13rem;
    min-height: 3rem;
    color: black;
    text-align: center;
}

.btn:hover {
    background-color: rgb(61,163,255);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Shadow for 3D effect */
    color: black;
}

@media screen and (max-width:940px) {
    .bg {
        right: -30vw
    }
    .homeContent {
        font-size: 10vw;
    }
    .hello {
        font-size: 4.5vw;
    }
}

@media screen and (max-width:480px) {
    .bg {
        right: -60vw
    }
    .homeContent {
        font-size: 10vw;
    }
    .hello {
        font-size: 4.5vw;
    }
}